import axios from 'axios';
import { getAuthorization } from './auth.js';
import { URL_API_FRELLO } from '../../constants/api';
import logger from '@frello-tech/front-utils/dist/utils/logger';
import configureURL from '../../utils/configure-url.js';

export const fetchModules = async (authData, args, params) => {
  const { classroomId, programId, unitId, lessonId } = args;
  const url = new URL(`${URL_API_FRELLO}me/classrooms/${classroomId}/program/${programId}/units/${unitId}/lessons/${lessonId}/modules`);
  const headers = await getAuthorization(authData);
  configureURL(url, params);
  return axios.get(
    url.toString(),
    headers
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(`can not contact ${URL_API_FRELLO}me/classrooms/${classroomId}/program/${programId}/units/${unitId}/lessons/${lessonId}/modules`);
      logger.error(error);
      return { classrooms: [{ name: 'test' }] };
    });
};

const getModuleUrl = (args) => {
  const { classroomId, programId, unitId, lessonId, moduleId } = args;
  if (classroomId && programId && unitId && lessonId) {
    return `me/classrooms/${classroomId}/program/${programId}/units/${unitId}/lessons/${lessonId}/modules/${moduleId}/withactivity`;
  }
  return `me/modules/${moduleId}/withactivity`;
};
export const fetchModuleByIdWithActivities = async (authData, args, params) => {
  const headers = await getAuthorization(authData);
  const url = new URL(`${URL_API_FRELLO}${getModuleUrl(args)}`);
  configureURL(url, params);

  return axios.get(
    url.toString(),
    headers
  ).then((response) => {
    return response.data;
  })
    .catch((error) => {
      console.log(`can not contact ${url}`);
      logger.error(error);
      return {};
    });
};

export const fetchSurvey = async (authData, moduleId, params) => {
  const headers = await getAuthorization(authData);
  const url = new URL(`${URL_API_FRELLO}me/modules/${moduleId}/questionnaire/create`);
  configureURL(url, params);

  return axios.get(
    url.toString(),
    headers
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(`can not contact ${url}`);
      logger.error(error);

      return { survey: {} };
    });
};

export const postSoundResponse = async (authData, { moduleId, questionId, responses, uri }, params) => {
  const headers = await getAuthorization(authData);
  const data = new FormData();
  const blob = await fetch(uri).then(r => r.blob());
  const url = new URL(`${URL_API_FRELLO}me/modules/${moduleId}/questionnaire/questions/${questionId}/sound`);
  configureURL(url, params);
  data.append('file', blob, `${questionId}.webm`);
  data.append('responses', JSON.stringify(responses));
  data.append('fileName', `${questionId}.webm`);

  return axios
    .put(url.toString(),
      data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          ...headers.headers
        }
      })
    .then(response => response.data)
    .catch(error => {
      console.log('Fail to send audio file from learner', url);
      logger.error(error);
      throw error;
    });
};

export const postResponsesForQuestion = async (authData, { moduleId, questionId, responses, skipped }, params) => {
  const headers = await getAuthorization(authData);
  const url = new URL(`${URL_API_FRELLO}me/modules/${moduleId}/questionnaire/questions/${questionId}`);
  configureURL(url, params);

  const payload = {
    ...responses,
    skipped
  };

  return axios.put(
    url.toString(),
    JSON.stringify(payload),
    { headers: { ...headers.headers, 'Content-Type': 'application/json' } } // TODO refactor that
  )
    .then((response) => response.data)
    .catch(error => {
      logger.error(error);
    });
};

export const endModuleById = async (authData, moduleId, params) => {
  const headers = await getAuthorization(authData);
  const url = new URL(`${URL_API_FRELLO}me/modules/${moduleId}/end`);
  configureURL(url, params);

  return await axios.put(
    url.toString(),
    JSON.stringify({}),
    { headers: { ...headers.headers, 'Content-Type': 'application/json' } }
  )
    .then((response) => response.data)
    .catch((error) => {
      console.log(`can not contact ${url}`);
      logger.error(error);

      return false;
    });
};

export const pauseModuleById = async (authData, moduleId, params) => {
  const headers = await getAuthorization(authData);
  const url = new URL(`${URL_API_FRELLO}me/module/${moduleId}/pause`);
  configureURL(url, params);

  return await axios.get(
    url.toString(),
    { headers: { ...headers.headers, 'Content-Type': 'application/json' } }
  )
    .then((response) => response.data)
    .catch((error) => {
      console.log(`can not contact ${url}`);
      logger.error(error);

      return false;
    });
};

export const resetSurvey = async (authData, moduleId, params) => {
  const headers = await getAuthorization(authData);
  const url = new URL(`${URL_API_FRELLO}me/modules/${moduleId}/questionnaire/reset`);
  configureURL(url, params);

  return await axios.put(
    url.toString(), JSON.stringify({}),
    { headers: { ...headers.headers, 'Content-Type': 'application/json' } }
  )
    .then(response => {
      return response.data;
    })
    .catch((error) => {
      console.log(`can not contact ${url}`);
      logger.error(error);

      return [];
    });
};

// todo:https://medium.com/ecmastack/uploading-files-with-react-js-and-node-js-e7e6b707f4ef
export const uploadMyFile = async (authData, { file, moduleId, questionId, responses }, params) => {
  const headers = await getAuthorization(authData);
  const url = new URL(`${URL_API_FRELLO}me/modules/${moduleId}/questionnaire/questions/${questionId}/sound`);
  configureURL(url, params);
  const data = new FormData();
  data.append('file', file);
  data.append('name', 'some value user types');
  data.append('description', 'some value user types');
  data.append('responses', responses);
  return axios
    .put(url.toString(),
      data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          ...headers.headers
        }
      })
    .then(response => response.data)
    .catch(error => {
      logger.error(error);
      throw error;
    });
};

export const patchResponse = async (authData, responseId, patch, params) => {
  const headers = await getAuthorization(authData);
  const url = new URL(`${URL_API_FRELLO}me/responses/${responseId}/correction`);
  configureURL(url, params);

  return await axios.patch(
    url.toString(),
    JSON.stringify(patch),
    { headers: { ...headers.headers, 'Content-Type': 'application/json' } }
  )
    .then(response => {
      return response.data;
    })
    .catch((error) => {
      console.log(`can not contact ${URL_API_FRELLO}me/responses/${responseId}/correction`);
      logger.error(error);
      return [];
    });
};
